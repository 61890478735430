<template>
    <div class="row">
      <div class="col-12">
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          @change="pagechanged"
          aria-controls="my-table"
        ></b-pagination>
      </div>
      <div class="col-12">
        <b-table :items="shops" :fields="columnsShops">
          
        </b-table>
      </div>
      
    </div>
  </template>
  <script>
  import { BaseTable } from "@/components";
  import NProgress from "nprogress";
  import axios from "axios";
  const tableColumns = ["Name", "Country", "City", "Salary"];
  const tableData = [
    {
      id: 1,
      name: "Dakota Rice",
      salary: "$36.738",
      country: "Niger",
      city: "Oud-Turnhout",
    },
    {
      id: 2,
      name: "Minerva Hooper",
      salary: "$23,789",
      country: "Curaçao",
      city: "Sinaai-Waas",
    },
    {
      id: 3,
      name: "Sage Rodriguez",
      salary: "$56,142",
      country: "Netherlands",
      city: "Baileux",
    },
    {
      id: 4,
      name: "Philip Chaney",
      salary: "$38,735",
      country: "Korea, South",
      city: "Overland Park",
    },
    {
      id: 5,
      name: "Doris Greene",
      salary: "$63,542",
      country: "Malawi",
      city: "Feldkirchen in Kärnten",
    },
    {
      id: 6,
      name: "Mason Porter",
      salary: "$98,615",
      country: "Chile",
      city: "Gloucester",
    },
    {
      id: 7,
      name: "Jon Porter",
      salary: "$78,615",
      country: "Portugal",
      city: "Gloucester",
    },
  ];
  const url_api = process.env.VUE_APP_API_BASE_URL;
  export default {
    components: {
      BaseTable,
    },
  
    data() {
      return {
        links: [],
        currentPage: 1,
        rows: 0,
        perPage: 20,
        columnsDisplayListChoose: [
          {
            label: "id",
            field: "id",
            type: "id",
            html: true,
            tdClass: "text-left",
            thClass: "text-left",
            sortable: false,
          },
          {
            label: "account_name",
            field: "account_name",
            type: "text",
            tdClass: "text-left",
            thClass: "text-left",
            sortable: false,
          },
        ],
        columnsShops: [
          { key: "id", label: "ID", sortable: true, sortDirection: "desc" },
          { key: "account_name", label: "Account", sortable: true },
          { key: "notes", label: "notes" },
          { key: "actions", label: "Actions" },
        ],
        totalRows: "",
  
        serverParams: {
          sort: {
            field: "id",
            type: "desc",
          },
          page: 1,
          perPage: 20,
        },
        isLoading: true,
        shops: [],
        table1: {
          title: "Simple Table",
          columns: [...tableColumns],
          data: [...tableData],
        },
        table2: {
          title: "Table on Plain Background",
          columns: [...tableColumns],
          data: [...tableData],
        },
      };
    },
    methods: {
      /**
       *
       */
      pagechanged(page) {
        if (this.serverParams.page !== page) {
          this.updateParams({ page: page });
          this.getShops(page, this.links[page]["url"]);
        }
      },
  
      linkGen(pageNum) {
        return pageNum === 1 ? "?" : `?page=${pageNum}`;
      },
      customPageChange(customCurrentPage) {
        this.pageChanged({ currentPage: customCurrentPage });
      },
      customPerPageChange(customPerPage) {
        this.perPageChanged({ currentPerPage: customPerPage });
      },
      updateParams(newProps) {
        this.serverParams = Object.assign({}, this.serverParams, newProps);
      },
      onPageChange({ currentPage }) {
        console.log(currentPage);
        if (this.serverParams.page !== currentPage) {
          this.updateParams({ page: currentPage });
          this.getShops(currentPage);
        }
      },
      onPerPageChange({ currentPerPage }) {
        if (this.limit !== currentPerPage) {
          this.limit = currentPerPage;
          this.updateParams({ page: 1, perPage: currentPerPage });
          this.getShops(1);
        }
      },
      showList() {
        alert("ok");
      },
      editShop() {},
      updateShop() {},
      deleteShop() {},
      getAuthorizationForShop() {},
      getShops(page, url = null) {
        NProgress.start();
        NProgress.set(0.1);
        if (url == null) {
          url =
            url_api +
            "/dropship/account?page=" +
            page +
            "&SortField=" +
            this.serverParams.sort.field +
            "&SortType=" +
            this.serverParams.sort.type +
            "&limit=" +
            this.serverParams.perPage;
        }
        axios
          .get(url)
          .then((response) => {
            console.log(response);
            this.shops = response.data.objs.data;
            this.totalRows = response.data.objs.data.lenght;
            this.currentPage = response.data.objs.current_page;
            this.rows = response.data.objs.total;
            this.perPage = response.data.objs.per_page;
            this.links = response.data.objs.links;
            // this.shops = response.data.shops;
            // Complete the animation of theprogress bar.
            NProgress.done();
            this.isLoading = false;
          })
          .catch((response) => {
            // Complete the animation of theprogress bar.
            NProgress.done();
            setTimeout(() => {
              this.isLoading = false;
            }, 500);
          });
      },
    },
    created: function () {
      this.getShops(1);
      // Fire.$on("Delete_Product", () => {
      //   this.Get_Products(this.serverParams.page);
      //   // Complete the animation of theprogress bar.
      //   setTimeout(() => NProgress.done(), 500);
      // });
  
      // Fire.$on("Event_import", () => {
      //   setTimeout(() => {
      //     this.Get_Products(this.serverParams.page);
      //     this.$bvModal.hide("importProducts");
      //   }, 500);
      // });
    },
  };
  </script>
  <style></style>
  