<template>
  <div class="row">
    <div class="col-12">
      <b-form-group>
        <b-input-group>
          <b-form-input
            id="filter-input"
            v-model="filter"
            :update="searchProduct()"
            :disabled="searchProcess"
            type="search"
            placeholder="Type to Search"
          ></b-form-input>
        </b-input-group>
      </b-form-group>
    </div>

    <div class="col-12">
      <!-- <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        @change="pagechanged"
        aria-controls="my-table"
      ></b-pagination> -->
      <b-button
        v-if="page_token != '' && page_token !== undefined"
        variant="primary"
        @click="next_page()"
        >Next</b-button
      >
    </div>
    <!-- <div class="col-12">
        <vue-good-table
          max-height="1000px"
          :fixed-header="true"
          mode="remote"
          :columns="columnsDisplayListChoose"
          :totalRows="totalRows"
          :rows="shops"
          @on-page-change="onPageChange"
          @on-per-page-change="onPerPageChange"
          :select-options="{
            enabled: true,
            clearSelectionText: '',
            selectOnCheckboxOnly: true,
          }"
          :pagination-options="{ enabled: false }"
        >
        </vue-good-table>
      </div> -->
    <div class="col-12">
      <b-table
        :items="products_full"
        :fields="columnsShops"
        responsive="sm"
        ref="selectableTable"
        selectable
        @row-selected="onRowSelected"
      >
        <template #row-details="row">
          <b-card>
            <div>
              <b-form @submit="onSubmit(row.item, row.index)">
                <div
                  class="col-12"
                  v-for="(detail, index) in row.item.skus"
                  :key="index"
                >
                  <b-form-group>
                    <b-row class="my-1">
                      <b-col sm="1">
                        <label :for="'name_${detail.id}'">Name:</label>
                      </b-col>
                      <b-col sm="3">
                        <b-form-input
                          :id="'name_${detail.id}'"
                          v-model="detail.seller_sku"
                          type="text"
                          required
                        ></b-form-input>
                      </b-col>
                    </b-row>
                    <b-row class="my-1">
                      <b-col sm="1">
                        <label :for="'stock_${detail.id}'">Quatity:</label>
                      </b-col>
                      <b-col sm="3">
                        <b-form-input
                          :id="'stock_${detail.id}'"
                          v-model="detail.inventory[0].quantity"
                          type="text"
                          required
                        ></b-form-input>
                      </b-col>
                    </b-row>
                    <b-row class="my-1">
                      <b-col sm="1">
                        <label :for="'price_${detail.id}'">Price:</label>
                      </b-col>
                      <b-col sm="3">
                        <b-form-input
                          :id="'price_${detail.id}'"
                          v-model="detail.price.tax_exclusive_price"
                          type="text"
                          required
                        ></b-form-input>
                      </b-col>
                    </b-row>
                  </b-form-group>
                </div>
                <b-button type="submit" variant="primary">Submit</b-button>
              </b-form>
            </div>
          </b-card>
        </template>
        <template #cell(selected)="{ rowSelected }">
          <template v-if="rowSelected">
            <span aria-hidden="true">&check;</span>
            <span class="sr-only">Selected</span>
          </template>
          <template v-else>
            <span aria-hidden="true">&nbsp;</span>
            <span class="sr-only">Not selected</span>
          </template>
        </template>
        <template #cell(actions)="row">
          <b-button variant="primary" @click="deleteproduct(row.item.id)"
            >Delete</b-button
          >
          |
          <b-button
            variant="primary"
            @click="editproduct(row.item.id, $event.target, row.index)"
            >Edit</b-button
          >
        </template>
        <template #cell(images)="row">
          <b-img-lazy
            v-if="row.item.images.length > 0"
            thumbnail
            height="100px"
            width="100px"
            fluid
            :src="row.item.images[0]"
            alt="image"
            :class="'zoom'"
          ></b-img-lazy>
        </template>
        <template #cell(skus)="row">
          <div
            class="text-center"
            v-for="(detail, index) in row.item.skus"
            :key="index"
          >
            {{ detail.seller_sku }}
            <b-badge v-if="detail.inventory[0].quantity >= 100" variant="light"
              >{{ detail.inventory[0].quantity }} -
              {{ detail.price.tax_exclusive_price }}$</b-badge
            >
            <b-badge v-if="detail.inventory[0].quantity < 100" variant="warning"
              >{{ detail.inventory[0].quantity }} -
              {{ detail.price.tax_exclusive_price }}$</b-badge
            >
          </div>
        </template>
        <template #cell(id)="row">
          <a
            v-if="
              row.item.skus.length > 0 &&
              row.item.skus[0].seller_sku != undefined
            "
            :href="
              'https://www.amazon.com/gp/product/' +
              row.item.skus[0].seller_sku.split('_')[0]
            "
            target="_blank"
          >
            {{ row.item.id }}
          </a>
          <span v-else>
            {{ row.item.id }}
          </span>
        </template>
      </b-table>
      <p>
        <b-button size="sm" @click="selectAllRows">Select all</b-button>
        <b-button size="sm" @click="clearSelected">Clear selected</b-button>
        <b-button size="sm" @click="deleteSelectedProducts"
          >Delete selected</b-button
        >
      </p>
    </div>
    <!-- <div class="col-12">
        <card :title="table1.title">
          <div class="table-responsive">
            <base-table
              :data="table1.data"
              :columns="table1.columns"
              thead-classes="text-primary"
            >
            </base-table>
          </div>
        </card>
      </div>
  
      <div class="col-12">
        <card class="card-plain">
          <div class="table-full-width table-responsive">
            <base-table
              :title="table2.title"
              :sub-title="table2.subTitle"
              :data="table2.data"
              :columns="table2.columns"
            >
            </base-table>
          </div>
        </card>
      </div> -->

    <b-alert
      v-if="isLoading"
      style="
        position: absolute;
        width: 50%;
        top: 50px;
        left: 25%;
        z-index: 10000;
      "
      variant="warning"
      show
      >Đợi xử lý</b-alert
    >
  </div>
</template>
  <script>
import { BaseTable } from "@/components";
import NProgress from "nprogress";
import axios from "axios";
import formMixin from "@/mixins/form-mixin";

const url_api = process.env.VUE_APP_API_BASE_URL;

export default {
  components: {
    BaseTable,
  },

  data() {
    return {
      form: [],
      links: [],
      currentPage: 1,
      rows: 0,
      perPage: 100,
      filter: "",
      searchProcess: false,
      columnsShops: [
        "selected",
        { key: "id", label: "ID", sortable: true, sortDirection: "desc" },
        // { key: "name", label: "Name", sortable: true },
        { key: "images", label: "images" },
        { key: "skus", label: "skus" },
        { key: "actions", label: "Actions" },
      ],
      totalRows: "",

      serverParams: {
        sort: {
          field: "id",
          type: "desc",
        },
        search: "",
        page: 1,
        perPage: 100,
      },
      isLoading: true,
      products: [],
      products_img_empty: [],
      products_full: [],
      productSelects: [],
      selectedIds: [],
      page_token: "",
    };
  },
  methods: {
    /**
     *
     */
    editproduct(id, e, i) {
      this.products_full[i]["_showDetails"] = true;
    },
    onSubmit(product, i) {
      //send product update to server
      var idShop = this.$route.params.id;
      var url = url_api + "/dropship/products_tts/update/" + idShop;
      axios
        .post(url, { product: product })
        .then((response) => {
          console.log(response);
          this.products_full[i]["_showDetails"] = false;
          this.$notify({
            type: "success",
            message: "Update Thành công!",
            icon: "tim-icons icon-bell-55",
          });
        })
        .catch((response) => {
          // Complete the animation of theprogress bar.
          this.$notify({
            type: "danger",
            message: "Update không thành công!",
            icon: "tim-icons icon-bell-55",
          });
          console.log(response);
        });
      return false;
    },
    onReset() {},
    searchProduct() {
      if (this.filter.length <= 5) {
        return;
      }
      // console.log(this.filter);
      if (this.serverParams.search !== this.filter) {
        this.serverParams.search = this.filter;
        this.getProducts(1);
      }
    },
    pagechanged(page) {
      if (this.serverParams.page !== page) {
        this.updateParams({ page: page });
        // this.getShops(page, this.links[page]["url"]);
        this.getProducts(page);
      }
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    onRowSelected(items) {
      this.selectedIds = [];
      this.productSelects = [];
      this.products_full.forEach((element, index) => {
        this.products_full[index]["_rowVariant"] = "";
        this.products_full[index]["selected"] = false;
      });
      items.forEach((row, index) => {
        this.selectedIds.push(row.id);
        var pTemp = this.products_full.filter((p) => p.id == row.id);
        if (pTemp) {
          pTemp[0]["_rowVariant"] = "success";
          pTemp[0]["selected"] = true;
          this.productSelects.push(pTemp[0]);
        }
      });
      // this.productSelects = items;
      console.log(this.productSelects);
    },
    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`;
    },
    customPageChange(customCurrentPage) {
      this.pageChanged({ currentPage: customCurrentPage });
    },
    customPerPageChange(customPerPage) {
      this.perPageChanged({ currentPerPage: customPerPage });
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    onPageChange({ currentPage }) {
      console.log(currentPage);
      if (this.serverParams.page !== currentPage) {
        this.updateParams({ page: currentPage });
        this.getShops(currentPage);
      }
    },
    onPerPageChange({ currentPerPage }) {
      if (this.limit !== currentPerPage) {
        this.limit = currentPerPage;
        this.updateParams({ page: 1, perPage: currentPerPage });
        getProducts(page);
      }
    },
    showList() {
      alert("ok");
    },
    editShop() {},
    updateShop() {},
    deleteShop() {},
    getAuthorizationForShop() {},
    deleteSelectedProducts() {
      if (confirm("Do you really want to delete?")) {
        var idShop = this.$route.params.id;
        var url = url_api + "/dropship/products_tts/delete_selected/" + idShop;
        axios
          .post(url, {
            selectedIds: this.selectedIds,
          })
          .then((response) => {
            console.log(response);

            this.products_full = this.products_full.filter((x) => {
              return !this.selectedIds.includes(x.id);
            });
            NProgress.done();
            this.isLoading = false;
          })
          .catch((response) => {
            // Complete the animation of theprogress bar.
            NProgress.done();
            setTimeout(() => {
              this.isLoading = false;
            }, 500);
          });
      }
    },
    deleteproduct(productid) {
      if (confirm("Do you really want to delete?")) {
        var idShop = this.$route.params.id;
        var url =
          url_api + "/dropship/products_tts/delete/" + idShop + "/" + productid;
        axios
          .get(url)
          .then((response) => {
            console.log(response);

            this.products_full = this.products_full.filter(
              (x) => x.id != productid
            );
            NProgress.done();
            this.isLoading = false;
          })
          .catch((response) => {
            // Complete the animation of theprogress bar.
            NProgress.done();
            setTimeout(() => {
              this.isLoading = false;
            }, 500);
          });
      }
    },
    deactivateproduct(product) {
      console.log(product);
      var idShop = this.$route.params.id;
      var url =
        url_api +
        "/dropship/products_tts/deactive/" +
        idShop +
        "/" +
        product.item.id;
      axios
        .get(url)
        .then((response) => {
          console.log(response);

          NProgress.done();
          this.isLoading = false;
        })
        .catch((response) => {
          // Complete the animation of theprogress bar.
          NProgress.done();
          setTimeout(() => {
            this.isLoading = false;
          }, 500);
        });
    },
    next_page() {
      this.getProducts();
    },
    getProducts(page) {
      // alert(this.$route.params.id);
      //send request to server get product tiktok with shop
      NProgress.start();
      NProgress.set(0.1);
      if (this.searchProcess) {
        return;
      }
      this.isLoading = true;
      this.searchProcess = true;
      this.products = [];
      this.products_img_empty = [];
      this.products_full = [];
      var idShop = this.$route.params.id;
      var url =
        url_api +
        "/dropship/products_tts/" +
        idShop +
        "?page_token=" +
        encodeURIComponent(this.page_token) +
        "&search=" +
        this.filter;
      axios
        .get(url)
        .then((response) => {
          console.log(response);

          this.searchProcess = false;
          this.page_token = response.data.page_token;
          this.products = response.data.productdata.products;
          if (this.products.length == 0) {
            this.$notify({
              type: "danger",
              message: "Không còn sản phẩm!",
              icon: "tim-icons icon-bell-55",
            });
          }
          this.products.forEach((product) => {
            if (product.missingImg) {
              this.products_img_empty.push(product);
            }
            this.products_full.push(product);
          });
          // this.rows = response.data.productdata.total;
          // this.perPage = 100;
          // this.currentPage = response.data.page;

          console.log(this.page_token);
          if (this.products_img_empty.length > 0) {
            this.getdetail(0, idShop);
          }

          // this.shops = response.data.shops;
          // Complete the animation of theprogress bar.
          NProgress.done();
          this.isLoading = false;
        })
        .catch((response) => {
          // Complete the animation of theprogress bar.
          NProgress.done();
          setTimeout(() => {
            this.isLoading = false;
          }, 500);
        });
    },
    getdetail(index = 0, idShop) {
      return new Promise(async (resolve) => {
        if (index >= this.products_full.length) {
          return resolve();
        }
        if (!this.products_full[index]["missingImg"]) {
          return resolve(this.getdetail(++index, idShop));
        }
        axios
          .get(
            url_api +
              "/dropship/products_tts/detail/" +
              idShop +
              "/" +
              this.products_full[index]["id"]
          )
          .then((response) => {
            try {
              this.products_full[index]["missingImg"] = false;
              this.products_full[index]["details"] =
                response.data.productdata.data;
              this.products_full[index]["images"] = [];
              this.products_full[index]["images"].push(
                response.data.productdata.data.images[0]["thumb_url_list"][0]
              );
            } catch (error) {
              return resolve();
            }

            // var es = this.products_full;
            // this.products_full.forEach((element, index2) => {
            //   var p= this.products_img_empty.filter((x) => {
            //     if ((x.id = element.id)) {
            //       return x;
            //     }
            //   });
            //   if(p){
            //     es[index2] =p;
            //   }
            // });
            // this.products_full=es;
            //  this.products_full.push(this.products_img_empty[index]);
            // console.log(this.products_img_empty[index]);
            return resolve(this.getdetail(++index, idShop));
          });
      });
    },
  },
  created: function () {
    this.getProducts(1);
    // Fire.$on("Delete_Product", () => {
    //   this.Get_Products(this.serverParams.page);
    //   // Complete the animation of theprogress bar.
    //   setTimeout(() => NProgress.done(), 500);
    // });

    // Fire.$on("Event_import", () => {
    //   setTimeout(() => {
    //     this.Get_Products(this.serverParams.page);
    //     this.$bvModal.hide("importProducts");
    //   }, 500);
    // });
  },
};
</script>
  <style></style>
  