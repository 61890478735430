<template>
  <div class="row">
    <div class="col-12">
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        @change="pagechanged"
        aria-controls="my-table"
      ></b-pagination>
    </div>
    <!-- <div class="col-12">
      <vue-good-table
        max-height="1000px"
        :fixed-header="true"
        mode="remote"
        :columns="columnsDisplayListChoose"
        :totalRows="totalRows"
        :rows="shops"
        @on-page-change="onPageChange"
        @on-per-page-change="onPerPageChange"
        :select-options="{
          enabled: true,
          clearSelectionText: '',
          selectOnCheckboxOnly: true,
        }"
        :pagination-options="{ enabled: false }"
      >
      </vue-good-table>
    </div> -->
    <div class="col-12">
      <b-table :items="shops" :fields="columnsShops">
        <template #cell(actions)="row">
          <router-link
            title="Report"
            :to="'/tiktokmanage/products_shop/' + row.item.id"
          >
            <b-button variant="primary">Products</b-button>
          </router-link>
          |
          <b-button variant="primary" @click="ExportAsin(row.item.id, 1)"
            >Export Asin</b-button
          >
        </template>
        <template #row-details="row">
          <b-card>
            <div>
              <b-card class="mt-3">
                <pre class="m-0">{{ row.item }}</pre>
              </b-card>
            </div>
          </b-card>
        </template>
      </b-table>
    </div>
    <div>
      <b-modal
        id="modal-export-asin"
        hide-backdrop
        hide-footer
        hide-header
        no-close-on-backdrop
      >
        <b-progress :max="100" variant="success" height="2rem">
          <b-progress-bar :value="progress_bar_value">
            <span
              >Progress:
              <strong
                >{{ progress_bar_value }}% {{ current_value }} /
                {{ product_total }}</strong
              ></span
            >
          </b-progress-bar>
        </b-progress>
      </b-modal>
    </div>
    <!-- <div class="col-12">
      <card :title="table1.title">
        <div class="table-responsive">
          <base-table
            :data="table1.data"
            :columns="table1.columns"
            thead-classes="text-primary"
          >
          </base-table>
        </div>
      </card>
    </div>

    <div class="col-12">
      <card class="card-plain">
        <div class="table-full-width table-responsive">
          <base-table
            :title="table2.title"
            :sub-title="table2.subTitle"
            :data="table2.data"
            :columns="table2.columns"
          >
          </base-table>
        </div>
      </card>
    </div> -->
  </div>
</template>
<script>
import { BaseTable } from "@/components";
import NProgress from "nprogress";
import axios from "axios";
import * as XLSX from "xlsx/xlsx.mjs";
import * as fs from "fs";
XLSX.set_fs(fs);
/* load 'stream' for stream support */
import { Readable } from "stream";
XLSX.stream.set_readable(Readable);

/* load the codepage support library for extended support with older formats  */
import * as cpexcel from "xlsx/dist/cpexcel.full.mjs";
XLSX.set_cptable(cpexcel);
const tableColumns = ["Name", "Country", "City", "Salary"];
const tableData = [
  {
    id: 1,
    name: "Dakota Rice",
    salary: "$36.738",
    country: "Niger",
    city: "Oud-Turnhout",
  },
  {
    id: 2,
    name: "Minerva Hooper",
    salary: "$23,789",
    country: "Curaçao",
    city: "Sinaai-Waas",
  },
  {
    id: 3,
    name: "Sage Rodriguez",
    salary: "$56,142",
    country: "Netherlands",
    city: "Baileux",
  },
  {
    id: 4,
    name: "Philip Chaney",
    salary: "$38,735",
    country: "Korea, South",
    city: "Overland Park",
  },
  {
    id: 5,
    name: "Doris Greene",
    salary: "$63,542",
    country: "Malawi",
    city: "Feldkirchen in Kärnten",
  },
  {
    id: 6,
    name: "Mason Porter",
    salary: "$98,615",
    country: "Chile",
    city: "Gloucester",
  },
  {
    id: 7,
    name: "Jon Porter",
    salary: "$78,615",
    country: "Portugal",
    city: "Gloucester",
  },
];
const url_api = process.env.VUE_APP_API_BASE_URL;
export default {
  components: {
    BaseTable,
  },

  data() {
    return {
      links: [],
      currentPage: 1,
      rows: 0,
      perPage: 50,
      columnsDisplayListChoose: [
        {
          label: "id",
          field: "id",
          type: "id",
          html: true,
          tdClass: "text-left",
          thClass: "text-left",
          sortable: false,
        },
        {
          label: "account_name",
          field: "account_name",
          type: "text",
          tdClass: "text-left",
          thClass: "text-left",
          sortable: false,
        },
      ],
      columnsShops: [
        { key: "id", label: "ID", sortable: true, sortDirection: "desc" },
        { key: "account_name", label: "Account", sortable: true },
        { key: "notes", label: "notes" },
        { key: "actions", label: "Actions" },
      ],
      totalRows: "",

      serverParams: {
        sort: {
          field: "id",
          type: "desc",
        },
        page: 1,
        perPage: 50,
      },
      isLoading: true,
      shops: [],
      products: [],
      product_total: 0,
      progress_bar_value: 0,
      current_value: 0,
      table1: {
        title: "Simple Table",
        columns: [...tableColumns],
        data: [...tableData],
      },
      table2: {
        title: "Table on Plain Background",
        columns: [...tableColumns],
        data: [...tableData],
      },
    };
  },
  methods: {
    /**
     *
     */
    resetModal() {
      this.products = [];
      this.product_total = 0;
      this.progress_bar_value = 0;
      this.current_value = 0;
    },
    ExportAsin(shopid, page) {
      //open modal info process
      this.products = [];
      this.$bvModal.show("modal-export-asin");
      this.getProducts(shopid, page);
    },
    pagechanged(page) {
      if (this.serverParams.page !== page) {
        this.updateParams({ page: page });
        this.getShops(page, this.links[page]["url"]);
      }
    },

    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`;
    },
    customPageChange(customCurrentPage) {
      this.pageChanged({ currentPage: customCurrentPage });
    },
    customPerPageChange(customPerPage) {
      this.perPageChanged({ currentPerPage: customPerPage });
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    onPageChange({ currentPage }) {
      console.log(currentPage);
      if (this.serverParams.page !== currentPage) {
        this.updateParams({ page: currentPage });
        this.getShops(currentPage);
      }
    },
    onPerPageChange({ currentPerPage }) {
      if (this.limit !== currentPerPage) {
        this.limit = currentPerPage;
        this.updateParams({ page: 1, perPage: currentPerPage });
        this.getShops(1);
      }
    },
    showList() {
      alert("ok");
    },
    editShop() {},
    updateShop() {},
    deleteShop() {},
    getAuthorizationForShop() {},
    getProducts(shopid, page) {
      // alert(this.$route.params.id);
      //send request to server get product tiktok with shop

      var url = url_api + "/dropship/products_tts/" + shopid + "?page=" + page;
      axios
        .get(url)
        .then((response) => {
          console.log(response);

          if (response.data.productdata.products.length == 0) {
            //export excel
            this.exportAsin(shopid);
          } else {
            this.products = this.products.concat(
              response.data.productdata.products
            );
            this.product_total = response.data.productdata.total;
            this.current_value = this.products.length;
            this.progress_bar_value =
              ((this.current_value / this.product_total) * 100).toFixed(2);

            page = parseInt(response.data.page) + 1;
            console.log(this.products);
            setTimeout(() => {
              this.getProducts(shopid, page);
            }, 500);
          }
        })
        .catch((response) => {
          console.log(response);
          setTimeout(() => {}, 500);
        });
    },
    exportAsin(shopid) {
      var rs = [];
      var rows = [];
      for (let index = 0; index < this.products.length; index++) {
        const element = this.products[index];

        if (element.skus.length > 0) {
          var rowSku = element.skus.map((row, index) => {
            if (!rs.includes(row.seller_sku.split("_")[0])) {
              rs.push(row.seller_sku.split("_")[0]);
              return { sku: row.seller_sku.split("_")[0] };
            }
          });
        }

        rows = rows.concat(rowSku);
      }
      // var rows = this.products.skus.map((row, index) => {
      //   row.skus.forEach((element) => {
      //     rs.push(element.seller_sku);
      //     return { sku: element.seller_sku };
      //   });
      //   // if (!rs.includes(row.skus[0].seller_sku)) {
      //   //   rs.push(row.skus[0].seller_sku);
      //   //   return { sku: row.skus[0].seller_sku };
      //   // }
      // });
      rows = rows.filter((row) => {
        return row !== undefined && row.sku !== "";
      });
      console.log(rows);
      const worksheet = XLSX.utils.json_to_sheet(rows);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Dates");
      XLSX.writeFile(workbook, "asin_list_"+shopid+"_"+(new Date().toJSON().slice(0,10))+".xlsx", { compression: true });

      this.$bvModal.hide("modal-export-asin");
      this.resetModal();
      this.$notify({
          type: "success",
          message: "Download file thành công!",
          icon: "tim-icons icon-bell-55",
        });
    },
    getShops(page, url = null) {
      NProgress.start();
      NProgress.set(0.1);
      this.shops = [];
      if (url == null) {
        url =
          url_api +
          "/dropship/account?page=" +
          page +
          "&SortField=" +
          this.serverParams.sort.field +
          "&SortType=" +
          this.serverParams.sort.type +
          "&limit=" +
          this.serverParams.perPage;
      }
      axios
        .get(url)
        .then((response) => {
          console.log(response);
          this.shops = response.data.objs.data;
          this.totalRows = response.data.objs.data.lenght;
          this.currentPage = response.data.objs.current_page;
          this.rows = response.data.objs.total;
          this.perPage = response.data.objs.per_page;
          this.links = response.data.objs.links;
          // this.shops = response.data.shops;
          // Complete the animation of theprogress bar.
          NProgress.done();
          this.isLoading = false;
        })
        .catch((response) => {
          // Complete the animation of theprogress bar.
          NProgress.done();
          setTimeout(() => {
            this.isLoading = false;
          }, 500);
        });
    },
  },
  created: function () {
    this.getShops(1);
    // Fire.$on("Delete_Product", () => {
    //   this.Get_Products(this.serverParams.page);
    //   // Complete the animation of theprogress bar.
    //   setTimeout(() => NProgress.done(), 500);
    // });

    // Fire.$on("Event_import", () => {
    //   setTimeout(() => {
    //     this.Get_Products(this.serverParams.page);
    //     this.$bvModal.hide("importProducts");
    //   }, 500);
    // });
  },
};
</script>
<style></style>
